.wrap {
    position: relative;
    cursor: pointer;
    display: inline-block;
    overflow: hidden;
}
.button {
    position: relative;
    z-index: 1;
}
.upload {
    position: absolute;
    opacity: 0;
    z-index: 0;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
}

.uploadLabel {
    cursor: pointer;
    z-index: 9999;
    opacity: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    padding: 0;
    margin: 0;
    outline: none;
    border: 0;
}
.progress.progress {
    font-size: 0.10rem;
    height: 0.2rem;
    border-radius: 6px;
    position: absolute;
    top: 1px;
    left: 2px;
    right: 2px;
    z-index: 2;
}
