.wrap {
    display: inline-block;
    position: relative;
    text-align: center;
    overflow: hidden;
    background-color: #fff;

    div, span {
        display: inline-block;
        border-radius: 50%;
        object-fit: cover;
        box-shadow: none;
        border: none;
        outline: none;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 2;
        width: 100%;
        height: 100%;
    }

    span {
        color: #fff;
        background: #777;
        font-size: 16px;
        justify-content: center;
        display: flex;
        align-items: center;
        z-index: 2;
    }

    div {
        background: transparent;
        background-size: cover;
    }
}
