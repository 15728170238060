.navigation {
    border-bottom: 1px solid #dee2e6;
    padding: 0 40px 15px 0;
    margin: 15px 0;
}

// Welcome message
.welcome {
    color: #7495ad;
    margin-top: 20px;
    margin-left: 10px;
}

// Removing bootstrap background color from dropdown items
.dropdown:active, .dropdown:focus {
    background-color: #f8f9fa!important;
    color: #3ec9cb;
}

// Dropdown items hover effect
.items i {
    margin-right: 8px;
}
div.items:hover  {
    color: #3ec9cb;
}

// User
.userName, .userType {
    margin-left: 5px;
    display: block;
}

.userType {
    color: #7495ad;
    font-size: 14px;
}

// Dropdown arrow
.btn {
    position: relative;
    padding-right: 25px !important;
}

.btn::after {
    position: absolute;
    right: 1%;
    top: 50%;
}

.btn:focus {
    box-shadow: none!important;
}

.avatar {
    position: absolute;
    top: 50%;
    margin-top: -20px;
    right: calc(100% - 10px);
}
