.logo {
    width: 75%;
}
.logo_link {
    display: inline-block;
}

.separator {
    margin: 20px 0;
    padding-bottom: 15px;
    border-bottom: 1px solid #dee2e6;
}

.pages {
    & > ul {
        margin: 0 0 1rem 0;
        padding: 0;
        list-style: none;


        & > li {
            &, a, .link {
                color: #222;
                transition: 0.3s;
                margin: 7px 0;
                display: block;
                text-decoration: none;

                i {
                    margin-right: 10px;
                }
            }

            a:hover, .link:hover {
                color: #3ec9cb;
                cursor: pointer;
            }
        }
    }
}

.contacts {
    border-top: 1px solid #ddd;
    width: 90%;
    left: 15px;
    margin-top: 50px;

    h3 {
        color: #999;
        text-transform: uppercase;
        margin-top: 20px;
        word-spacing: 1px;
        font-size: 15px;

    }

    a {
        display: block;
        margin-top: 10px;
        color: #222;
        transition: 0.3s;

        &:hover {
            color: #3ec9cb;
        }
    }
}

