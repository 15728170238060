.link{
    display: block;
    color: #3ec9cb;
    transition: 0.3s;
    text-decoration: none;
}

.link:hover {
    color: #7495ad;
    border-color: #7495ad;
    text-decoration: none;
}

.course_name {
    color: #333;
    font-size: 20px!important;
    font-weight: 500!important;
}

.group_name {
    color: #555;
    font-size: 18px!important;
    margin: 15px 0 30px!important;
    font-weight: 500!important;
}

.itemInfo {
    text-align: center;
    margin-top: 5px;
    padding: 5px 0 5px 0;
}

.second {
    color: #777;
}

.imageWrapper {
    position: relative;
    width: 100%;
    padding-top: 66.66%;
}

.image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.details {
    margin-top: 10px;
}
.info {
    margin-bottom: 15px;
    border: 1px solid #eee;
    padding-right: 0;
    padding-left: 0;
    box-shadow: 1px 1px 1px#eee;
}

