div.background {
    text-align: center;
    background: url('/assets/img/login.jpg') no-repeat;
    background-size: cover;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
    color: white;
}

div.logo img {
    width: 30%;
    margin-bottom: 40px;
}

// Login form
form {
    background: #fff;
    width: 50%;
    margin: 0 auto;
    border-radius: 10px;

    .form-wrap {
        padding: 20px 2em;
    }

    a.link {
        font-size: 16px;
        color: #3ec9cb;
        margin: 0.5rem 0 0.25rem;
        display: inline-block;
        transition: 0.3s;

        &:hover {
            color: #1a2042;
            text-decoration: none;
        }
    }

    // Input elements in login form
    input.form-control {
        margin: 0 auto 20px;
        background: #f5f5f5;
        height: 45px;
    }

    .text-danger {
        margin-top: -10px;
    }

    .list-errors {
        text-align: left;
        background: none;
        color: #dc3545;
        border: none;

        ul {
            margin: 0;
            padding: 0;
            font-size: 12px;

            & > li {
                margin: 0;
                padding: 0;
            }
        }
    }
}

//Subtitle control panel
h2.large {
    font-size: 18px;
    margin: 20px 0 40px 0;
}

// Login contact info
div.questions {
    margin-top: 50px;

    p {
        margin: 10px 0;
        font-size: 16px;
    }

    a {
        color: #3ec9cb;
        transition: 0.3s;

        &:hover {
            color: #ffffff;
            text-decoration: none;
        }

        .site {
            color: #ffffff;
            transition: 0.3s;
            &:hover {
                color: #3ec9cb;
                text-decoration: none;
            }
        }
    }
}
