.layout {
    background-color: #fff;
}

.sidebar {
    background: #f5f5f5;
    padding: 15px;
    min-height: 100vh;
}

.popup_title {
    display: block;
    width: 100%;
    text-align: center;
    font-weight: bold;
    font-size: 16px;
}

.video {
    max-width: 100%;
}
