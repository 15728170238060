
// Input placeholder
.input {
    &::placeholder {
        font-size: 15px;
    }
}

// Form labels
.label {
    margin-bottom: 10px;
    color: #333;
    font-size: 15px;
}
